@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #39b4ac;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #2a8274;
}

/* * {
  box-sizing: border-box !important; margin: 0!important;
  padding: 0!important;
} */

*:focus {
  outline: none !important;
}

a {
  color: black !important;
  text-decoration: none;
}

a:hover {
  color: black !important;
  text-decoration: none !important;
}

body {
  font-size: 16px !important;
}

table {
  table-layout: fixed;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: #fafafa;
}

td,
th {
  overflow: hidden;
}

/* remove default browser placeholder when not focused */
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-year-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-month-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-day-field,
input[type="date"]:not(:focus):in-range::-webkit-datetime-edit-text {
  color: transparent;
}

/* iOS background fix */
& > input[type="date"]:empty {
  background-color: #f9f9f6;
}

/* iOS fix for text alignment */
& > input::-webkit-date-and-time-value {
  text-align: left;
  color: var(--sg-black);
}

/* iOS date-placeholder fix */
& > input[type="date"]:not(:focus):empty:after {
  position: absolute;
  color: #aaa;
  content: attr(placeholder);
}

.react-datepicker__month-container {
  float: none !important;
}

#data-picker ul {
  height: 357px;
  width: 120px;
  overflow-x: scroll;
  flex-wrap: nowrap !important;
}

.dark .sketch-picker input {
  color: red;
  background-color: rgb(25, 25, 25);
  color: #f3f4f6;
  box-shadow: rgb(131, 131, 131) 0px 0px 0px 1px inset !important;
}

.bp5-popover-content,
.bp5-popover {
  @apply !rounded-md ;
}
